// ********************Variables CSS********************
$bg-color: #f3f3f4;
$theme-color: #1AA7DE;
$theme-light-color: #d9efe1;
$theme-info: #23c6c8;
$theme-danger: #ed5565;
$theme-primary: #1c84c6;
$theme-warning: #f8ac59;
$theme-fuchsia: rgb(162 28 175);
$theme-blue: #20283e;
$theme-rose: rgb(159 18 57);
$white: #fff;
$black: #000;
$font-size: 13px;
$text-color: #676a6c;
$grey: #999c9e;
$shadow: 0 3px 7px 0 #a8a8a8;
$shadow-sm: 0 0 3px rgb(86 96 117 / 70%);
$shadow-md: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
$border-transparent: 1px solid transparent;
$border-color: #e7eaec;
$border: 1px solid $border-color;
$sidebar-bg: #2f4050;
$sidebar-dark-bg: #293846;
$sidebar-text: #DFE4ED;
$sidebar-links-text: #a7b1c2;
$border-radius: 4px;
$filterShadow: 0 -1px 0 rgba(0, 0, 0, .1215686275);
$red:#ff0000;
$lightRed:#ffd9d9;
$blue:#0d6efd;
$lightBlue:#b8e1ff;