// ********************Imported SASS********************
@import '../styles/theme.scss';

// ********************Font Family********************
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

// ********************Toastify********************
@import "react-toastify/dist/ReactToastify.css";
// ********************Common CSS********************

html,
#root {
    height: 100%;
}

body {
    word-break: break-word;
    background-color: $bg-color;
    font-size: $font-size;
    color: $text-color;
    overflow-x: hidden;
    font-weight: 500;
    font-family: "open sans", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    height: 100%;

}


.invalid-error-class {
    color: $theme-danger;
    font-size: 12px;
    font-weight: 500;
}

.divider {
    height: 1px;
    overflow: hidden;
    background-color: $border-color;
}

.not-allowed {
    pointer-events: none !important;
    color: #676A6C !important;
}

.text-muted {
    color: #8095a8 !important;
}

.text-theme {
    color: $theme-color !important;
}

.text-bg-success {
    background-color: $theme-color !important;
}

.text-bg-warning {
    background-color: $theme-warning !important;
}

.text-bg-primary {
    background-color: $theme-primary !important;
}

.bg-theme {
    background-color: $theme-color;
    border: 1px solid $theme-color;
}


.bg-dark {
    background-color: $sidebar-dark-bg !important;
}

.py-half {
    padding: 2px;
}

.rounded-half {
    border-radius: 2px;
}

.fs-10 {
    font-size: 10px;
}

ul li {
    list-style-position: inside;
}




// ******************** React Calender CSS********************
.react-calendar {
    width: 282px !important;

}

// ******************** Table CSS********************
.data-list-table {
    table {
        color: $text-color;
        white-space: nowrap;

        thead {
            text-align: left;

            th {
                min-width: 50px;
                padding-inline: 10px;
                font-weight: 600;
                color: $black;
            }
        }

        tbody {
            text-align: left;

            td {
                min-width: 50px;
                padding-inline: 10px;
            }
        }
    }
}

.table>:not(caption)>*>* {
    padding: 4px;
}

.coupon-table th,
.coupon-table td {
    min-width: 150px !important;
}



// ********************Buttons CSS********************
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    border-radius: $border-radius;
    font-size: $font-size;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;

    &:focus {
        box-shadow: none;
        color: inherit;
    }

}

.btn-theme {
    background-color: $theme-color !important;
    border: 1px solid $theme-color !important;
    color: $white !important;
    box-shadow: none;

    &:hover {
        box-shadow: $shadow;
        background-color: $theme-color !important;
        border: 1px solid $theme-color !important;
        color: $white !important;
    }

    &:focus {
        color: $white !important;
    }
}

.btn-white {
    color: $text-color;
    background: $white;
    border: 1px solid $border-color !important;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        box-shadow: $shadow;
        background-color: $white !important;
        border: 1px solid $border-color !important;
        color: $text-color !important;
    }
}

.btn-group {
    .btn-sm {
        padding: 3px 6px;
    }
}


.coupon-box select {
    --webkit-appearance: listbox !important;
    margin-right: 10px;
}


.fa-circle {
    color: $theme-color;
    font-size: 7px;
}

.fa-check {
    color: $theme-color;
    font-size: 13px;
}

.badge-width {
    width: 150px;
    display: inline-block;
    text-align: center;
}

.ReadMore {
    color: $theme-color;
}


.input-group-text {
    border-radius: $border-radius;
    border-color: $border-color;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


// ******************** Modal Syle CSS********************
.modal-content {
    border-radius: $border-radius;
}

.close-modal-button {
    position: absolute;
    top: 7px;
    right: 12px;
}

.btn-close {
    background: url('../images/svg/download.svg') center/1em auto no-repeat;
    opacity: 0.5;
    box-shadow: none !important;
}



// ********************Layout Syle CSS********************
.layout-wrapper {
    width: 100%;
    height: 100%;
}


// ********************Header CSS********************
header {
    font-size: $font-size;
    box-shadow: $shadow-md;

    .header-profile-dropdown {
        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
        }

        button {
            font-size: 13px !important;
        }

        .dropdown-menu {
            box-shadow: $shadow-md;
            border: 0;
            position: absolute;

            &:before {
                position: absolute;
                content: "";
                top: -7px;
                left: 10px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $white;
                filter: drop-shadow($filterShadow);
            }

            a {
                font-size: 13px;
            }
        }
    }

    .notification-dropdown {
        min-width: 250px;

        &:before {
            position: absolute;
            content: "";
            top: -7px;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $white;
            filter: drop-shadow($filterShadow);
        }
    }



    .navbar-header {
        display: flex;
        column-gap: 10px;
        align-items: center;

    }

    input.form-control {
        font-size: 14px;
        color: $text-color;
    }

    .navbar-form-custom {
        padding: 0;
        width: 200px;
        display: inline-block;
    }


    .logout-btn {
        font-size: inherit;
        color: inherit;
    }

    .dropdown-button {
        background: transparent;
        border-color: transparent;
        font-size: inherit;
        color: inherit;

        .custom-badge {
            position: absolute !important;
            top: 1px !important;
            right: 3px !important;
            line-height: 12px;
            padding: 1px 4px;
            font-size: 8px;
        }

    }

    .dropdown-menu {
        box-shadow: $shadow-md;

        .dropdown-messages-box img {
            width: 38px;
            height: 38px;
        }

        .media-body {
            font-size: inherit;
            color: inherit;
        }

        .notification-text p {
            font-size: 13px;
        }
    }
}

.main-nav i {
    color: $grey;
}

// ********************Footer CSS********************

footer {
    background: none repeat scroll 0 0 $white;
    border-top: 1px solid $border-color;
    padding: 10px 0px;
    box-shadow: $shadow-md;
    margin-top: auto;

    p {
        span {
            font-weight: 700;
        }
    }
}

// ******************** Pages CSS ********************
.page-spacing {
    padding: 30px;

    @media all and (max-width:991px) {
        padding: 20px 15px;

    }

    @media all and (max-width:767px) {
        padding-inline: 15px;

    }
}


.dashboad-pages-wrapper {
    width: calc(100% - 270px);
    margin-left: 270px;
    overflow-x: hidden !important;
    transition: all 0.4s ease-in-out;

    @media all and (max-width:767px) {
        width: calc(100% - 195px);
        margin-left: 195px;

    }
}

.dashboard-mobile-pages {
    width: calc(100% - 80px);
    margin-left: 80px;
    transition: all 0.4s ease-in-out;
}

// ********************Side bar CSS********************


.mobile-screen {
    width: 80px !important;
    transition: all 0.4s ease-in-out;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    background-color: $sidebar-bg;
    border-color: $sidebar-bg;
    width: 270px;
    transform: translateX(0px);
    transition: all 0.4s ease-in-out;

    @media all and (max-width:767px) {
        width: 195px;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #C9C8C8;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme-color;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .sidebar-header {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../images/sidebar/header-profile.png);
        text-align: center;
        padding-block: 25px;

        .dropdown-menu {
            box-shadow: $shadow-sm;

            a {
                font-size: $font-size;
            }
        }

        .user-detail-box {
            width: 48px;
            height: 48px;
            margin: auto;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            p {
                color: $sidebar-text;
                font-weight: 600;
            }

        }

        button {
            font-size: $font-size !important;
        }
    }
}

.link-active {
    border-left: 4px solid $theme-color !important;
    background: $sidebar-dark-bg !important;
    color: $white;

    i,
    span {
        color: $white;
    }
}


a {
    text-decoration: none;
    color: black
}


// ********************Accordion SASS********************
.accordion-item {
    color: $sidebar-text;
    background-color: $sidebar-bg;
    border: none;
    border-left: 4px solid $sidebar-bg;


    &:hover {
        border-left: 4px solid $sidebar-dark-bg;
    }

    .single-link.accordion-button::after {
        content: none;
    }

    .accordion-button {
        background-color: transparent;
        color: $sidebar-links-text;
        font-size: $font-size;
        font-weight: 600;

        &:hover {
            background-color: $sidebar-dark-bg;
            color: $white;
        }

        &:not(.collapsed) {
            color: $white;
            background-color: $sidebar-dark-bg ;

            &::after {
                content: "\f107";
                background-image: none;
                transform: none;
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
            }
        }

        &::after {
            content: "\f104";
            background-image: none;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
        }

    }


    .accordion-body {
        background: $sidebar-dark-bg;

        .sidebar-sublink a {
            color: $sidebar-links-text;
            text-decoration: none;
            font-weight: 600;
            padding-block: 7px;
            display: inline-block;

            &:hover {
                color: $white;
            }
        }
    }

}

// ******************** Faq ********************


.faq-content-box {


    .accordion-item {
        color: $sidebar-bg;
        background-color: transparent;
        border: none;
        border-left: 4px solid $sidebar-bg;
    }


    .accordion-button {
        background-color: transparent !important;
        font-size: $font-size;
        font-weight: 600;
        border: 1px solid $border-color;
        color: $sidebar-bg;

        &:hover {
            color: $sidebar-bg;
        }

        &:not(.collapsed) {
            color: $black;
            border: 1px solid $border-color;

            &::after {
                content: "\f107";
                background-image: none;
                transform: none;
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
            }
        }

        &::after {
            content: "\f104";
            background-image: none;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            text-align: end;
        }

    }
}


.faq-content-box .accordion-body {
    border: 1px solid $border-color;
    border-top: 0;
    background-color: transparent;
    border-radius: 0 0 5px 5px;

    .sidebar-sublink a {
        color: $sidebar-links-text;
        text-decoration: none;
        font-weight: 600;
        padding-block: 7px;
        display: inline-block;

        &:hover {
            color: $white;
        }
    }
}



// .product-faq-accodion i {
//     color: $sidebar-dark-bg !important;
// }

// ******************** Verified Email Icon CSS********************

.email_verified {
    position: absolute;
    right: 0.5rem;
    bottom: 10px;
}

// ******************** Price Filter Syle CSS********************

.price-filter-dropdown {
    min-width: 200px !important;
    padding: 15px 12px !important;
    width: 200px !important;
}


// ******************** Download Invoices CSS********************

.download-invoices-links a {
    display: inline-block;
    width: 100%;
    color: $text-color;

    &:hover {

        background-color: $border-color;
    }

    &:focus {
        border: 0;
    }
}


// ******************** Coupon Module Select CSS ********************
.coupon-module select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
    background-repeat: no-repeat !important;
    background-position-x: 95% !important;
    background-position-y: 5px !important;
    text-align: left !important;
}



// ******************** Breadcrum Syle CSS********************
.breadcrum-box {
    padding: 10px 25px;
    background-color: $white;

    @media all and (max-width:767px) {
        padding-inline: 0px;

    }

    h2 {
        font-weight: 600;
    }

    .breadcrumb {
        flex-wrap: nowrap;

        .breadcrumb-item {
            a {
                color: $theme-color;
                text-decoration: none;
                font-weight: 600;
                white-space: nowrap;

                @media all and (max-width:575px) {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 40px;
                    display: inline-block;
                }
            }
        }
    }
}